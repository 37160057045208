<template>
  <div class="container mt-5 divContainerAdminGeneral">
    <ModalAdmin ref="ModalAdmin" @reloadTeacherList="getUsers"/>    
    <confirmationModal ref="confirmModal" @confirm="deleteUserApi" />
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral">Users</h2>
    </div>
    <hr class="w-100" />
    <p class="text-danger" v-if="errorMessage">
      {{ errorMessage }}
    </p>
    <div class="row w-100 divContainerAdminGeneral mt-3 d-none d-lg-block">      
      <div class="d-flex w-100 mb-2">
        <div class="col-2 titleAdminTableGeneral font-weight-bold">Name</div>
        <div class="col-3 titleAdminTableGeneral font-weight-bold">Email</div>
        <div class="col-2 titleAdminTableGeneral font-weight-bold">School</div>
        <div class="col-2 titleAdminTableGeneral font-weight-bold">Major</div>
        <div class="col-1 titleAdminTableGeneral font-weight-bold">Age</div>
        <div class="col-1 titleAdminTableGeneral font-weight-bold pl-0">Created</div>
        <div class="col-1 titleAdminTableGeneral"></div>
      </div>
      <div v-for="(user, index) in users" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{user.name}}</div>
        <div class="col-3 textTableAdminGeneral d-flex justify-content-start align-items-center">{{user.email}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{user.school}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{user.major}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center">{{user.age}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center pl-0">{{formatDate(user.createdAt)}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-end align-items-center pr-0"><i class="fas fa-edit p-2 icon_tool iconEdit" v-on:click="openModalUsers(user)"></i><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(user)"></i></div>
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !users.length">
        <h1 class="m-5">No Users to display.</h1>
      </div>

    </div>

    <div class="row w-100 card-deck m-0 justify-content-start divCardUserList d-lg-none">

      <div class="col-sm-6 col-8 p-0 mt-5" v-for="(user, index) in users" :key="index">
        <div class="card div-card-user">
          <div class="d-flex text-center">
            <h5 class="card-title cardTitleUser col-12 text-center px-2 fw-bold">{{user.name}}</h5>
          </div>

          <div class="row card-body">
            <span class="col-12 cardSubtitle text-muted text-center">Email: </span>
            <span class="col-12 cardContent text-center">{{user.email}}</span>

            <span class="col-6 cardSubtitle text-muted mt-2 text-center">School: </span>
            <span class="col-6 cardSubtitle text-muted mt-2 text-center">Major: </span>
            <span class="col-6 cardContent text-center">{{user.school}}</span>
            <span class="col-6 cardContent text-center">{{user.major}}</span>

            <span class="col-6 cardSubtitle text-muted mt-2 text-center">Created: </span>
            <span class="col-6 cardSubtitle text-muted mt-2 text-center">Age: </span>
            <span class="col-6 cardContent text-center">{{formatDate(user.createdAt)}}</span>
            <span class="col-6 cardContent text-center">{{user.age}}</span>

          </div>

          <div class="d-flex justify-content-around">
            <i class="fas fa-edit p-3 icon_tool iconEdit col-2" v-on:click="openModalUsers(user)"></i>
            <i class="fas fa-trash p-3 icon_tool iconDelete col-2" v-on:click="openConfirmationModal(user)"></i>
          </div>
        </div>
      </div>
    
    </div>




    <div class="col-12 p-4 text-center" v-if="loading">
      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>          
    </div>

  </div>
</template>
<script>
import moment from 'moment';
import confirmationModal from './confirmationDeleteModal.vue';
import ModalAdmin from './ModalAdmin'
import { mapActions } from 'vuex';
export default {
  name:"userList",
  data(){
    return{
      errorMessage: "",
      loading:false,
    }
  },
  components:{
    confirmationModal,
    ModalAdmin
  },
  props:['users'],
  methods:{
    ...mapActions('user', ['deleteUser']),
    deleteUserApi(id){
      this.deleteUser(id)
    },
    openConfirmationModal(user = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? " + user.name || ""
			this.$refs.confirmModal.assetId = user._id || ""
      $('#confirmationModal').modal('show')
    },
    openModalUsers(user = {}){
      this.$refs.ModalAdmin.name = user.name || ""
      this.$refs.ModalAdmin.email = user.email || ""
      this.$refs.ModalAdmin.userId = user._id || ""
      this.$refs.ModalAdmin.school = user.school || ""
      this.$refs.ModalAdmin.major = user.major || ""
      this.$refs.ModalAdmin.age = user.age || ""


      $('#usersEditModal').modal('show')

    },
    formatDate(date){
      return moment(date).format('MM/DD/YYYY')
    },
  },
  created(){
  }
}
</script>