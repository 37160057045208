import { createStore } from "vuex";
import user from './user/index'
import skills from './skills/index'
import events from './events/index'

export const store = createStore({
  modules:{
    user,
    skills,
    events
  }
})
