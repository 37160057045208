<template>
  <div class="div-content-event-list d-flex flex-column justify-content-center align-items-center mt-5">
    <confirmationModal ref="confirmModal" @confirm="deleteEventAPI" />
    <ModalEvent ref="saveModalEvents" />
    <div class="divAdminGeneral">
      <h2 class="titleAdminGeneral">Events</h2>
      <div class="d-flex flex-column align-items-end">
        <div>
          <button type="button" class="btn btn_admin_general" v-on:click="openModalEvents()">Add Event</button>
        </div>
      </div>
      <hr class="w-100" />
    </div>
    <div class="row divContainerAdminGeneral mt-2 mb-5">
      <div class="d-flex w-100 mb-2">
        <div class="col-1 titleAdminTableGeneral font-weight-bold">Picture</div>
        <div class="col-1 titleAdminTableGeneral font-weight-bold">Title</div>
        <div class="col-2 titleAdminTableGeneral font-weight-bold">Date</div>
        <div class="col-2 titleAdminTableGeneral font-weight-bold">Description</div>
        <div class="col-2 titleAdminTableGeneral font-weight-bold">Coordinates</div>
        <div class="col-1 titleAdminTableGeneral font-weight-bold">Host</div>
        <div class="col-1 titleAdminTableGeneral font-weight-bold">Price</div>
        <div class="col-2 titleAdminTableGeneral"></div>
      </div>
      <div v-for="(event, index) in events" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><img class="w-100" :src="event.picture" alt="" v-if="event.picture"><i v-else class="fas fa-images no-image-icon"></i></div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center">{{event.title}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{formatDate(event.date)}} ({{event.startTime}}-{{event.endTime}})</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{event.details}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-start align-items-center">{{event.address}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center">{{event.host}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center" v-if="event.price">{{event.price === 0 ?'FREE' :event.price}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center" v-else >{{event.minPrice}}-{{event.maxPrice}}</div>
        <div class="col-2 textTableAdminGeneral d-flex justify-content-around align-items-center"><a v-if="event.webLinkToTicket" :href="event.webLinkToTicket"><i class="fas fa-ticket-alt p-2 icon_tool iconTicket"></i></a> <i class="fas fa-edit p-2 icon_tool iconEdit" v-on:click="openModalEvents(event)"></i><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(event)"></i></div>
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !events.length">
        <h1 class="m-5">No Events to display.</h1>
      </div>

      <div class="col-12 p-4 text-center" v-if="loading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>

    </div>

  </div>
</template>
<script>
import { mapActions, mapState} from 'vuex'
import moment from 'moment';
import confirmationModal from './confirmationDeleteModal.vue';
import ModalEvent from './ModalEvents';
export default {
  name:"eventsList",
  data() {
    return{
      loading:false,
    }
  },
  components:{
    confirmationModal,
    ModalEvent
  },
  props:['events'],
  methods:{
    ...mapActions('events', ['deleteEvent']),
    deleteEventAPI(id){
      this.loading = true
      this.deleteEvent(id)
      this.loading = false
    },
    openConfirmationModal(event = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? " + event.title || ""
			this.$refs.confirmModal.assetId = event._id || ""
      $('#confirmationModal').modal('show')
    },
    openModalEvents(event = { location:{coordinates:[]} }){
      this.$refs.saveModalEvents.title = event.title || ""
      this.$refs.saveModalEvents.picture = event.picture || ""
			this.$refs.saveModalEvents.date = moment(event.date).format('yyyy-MM-DD') || ""
			this.$refs.saveModalEvents.time = moment(event.date).format('HH:mm') || ""
			this.$refs.saveModalEvents.description = event.description || ""
			this.$refs.saveModalEvents.address = ""
			this.$refs.saveModalEvents.city = event.city || ""
			this.$refs.saveModalEvents.state = event.state || ""
			this.$refs.saveModalEvents.eventId = event._id || ""
			this.$refs.saveModalEvents.schoolAssociatedWith = event.schoolAssociatedWith || ""
			this.$refs.saveModalEvents.price = event.price || 0
      if(!event.price){
        this.$refs.saveModalEvents.disabled = true
      }else{
        this.$refs.saveModalEvents.disabled = false
      }
      $('#eventsEditModal').modal('show')
    },
    formatDate(date){
      return moment(date).format('MMM/MM/DD')
    },
  }
}
</script>
