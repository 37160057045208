<template>
  <div class="modal fade" id="eventsEditModal" tabindex="-1" aria-labelledby="objectiveModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered">

      <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="objectiveModalLabel">Event</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="emptyMessage"></button>
          </div>

          <form class="w-100 py-5 px-4">

            <div class="form-row">

              <div class="form-group col-md-6">
                <label for="titleEdit" class="labelForm font-weight-bold">Title</label>
                <input type="text" id="titleEdit" class="form-control inputsForm" placeholder="title" v-model="title" v-on:keyup.enter="editEvent()">
              </div>

              <div class="form-group col-md-6">
                <label for="dateEdit" class="labelForm font-weight-bold">Date</label>
                <div class="d-flex">
                  <input type="date" class="dateEvent form-control inputsForm col-md-8" :min="dateDay" id="dateEdit" v-model="date">
                  <input type="time" class="timeEvent form-control inputsForm col-md-4" id="dateEdit" v-model="time">
                </div>
              </div>

              <div class="form-group col-md-12">
                <label for="description" class="labelForm font-weight-bold">Description</label>
                <input type="text" class="form-control inputsForm" id="description" placeholder="description" v-model="description" v-on:keyup.enter="editEvent()">
              </div>

              <div class="form-group col-md-12">
                <label for="" class="labelForm font-weight-bold">Address</label>
                <input type="text" class="form-control col-12 inputsForm" id="autocomplete" placeholder="address" v-model="address" v-on:keyup.enter="editEvent()">
              </div>

              <div class="form-group col-md-6">
                <label for="" class="labelForm font-weight-bold">School</label>
                <input type="text" class="form-control col-12 inputsForm" placeholder="school" v-model="schoolAssociatedWith" v-on:keyup.enter="editEvent()">
              </div>

              <div class="form-group col-md-6">
                <label for="" class="labelForm font-weight-bold">Price</label>
                <div class="d-flex justify-content-start align-items-center">
                  <input type="number" class="form-control inputsForm mr-3" id="priceID" placeholder="price" :disabled="disabled" v-model="price" v-on:keyup.enter="editEvent()">
                  <button type="button" class="btn btn_event_free" v-on:click="freePrice()">FREE</button>
                </div>
              </div>

            </div>

            <p class="text-danger" v-if="errorMessage">
              {{ errorMessage }}
            </p>
            
          </form>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg btnModalSecondary py-2 px-4" data-bs-dismiss="modal" v-on:click="emptyMessage">Close</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess py-2 px-4 ml-3" v-on:click="editEvent()" v-if="eventId">Edit</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess py-2 px-4 ml-3" v-on:click="editEvent()" v-else>Add new event</button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState} from 'vuex'
import moment from 'moment';
export default {
  name:"ModalEvent",
  data(){
    return{
      title: '',
      picture: '',
      date: '',
      time:'',
      description: '',
      eventId:"",
      errorMessage:"",
      address:'',
      longitude:"",
      latitude:"",
      city: "",
      state: "",
      address:"",
      placeId:"",
      coordinates: [],
      googleAutocomplete:{},
      schoolAssociatedWith:"",
      price: 0,
      disabled:false,
    }
  },
  methods:{
    ...mapActions('events', ['CreateNewEvent', 'updateEvent']),
    freePrice(){
      this.price = 0
      this.disabled = !this.disabled
    },
    editEvent(){
      this.errorMessage = ""
      if (!this.title) {
        this.errorMessage = "Please insert a valid title";
        return;
      } else if (!this.date) {
        this.errorMessage = "Please insert a valid date";
        return;
      }else if (!this.description) {
        this.errorMessage = "Please insert a valid description";
        return;
      }else if (!this.address) {
        this.errorMessage = "Please insert a valid coordinate";
        return;
      }
      let place = any = this.googleAutocomplete.getPlace();
      console.log(place)
      if(!place.address_components.filter((c) => { return c.types[0] == "locality" }).length){
        this.errorMessage = "Please insert a valid address";
        return;
      }else if(!place.address_components.filter((c) => { return c.types[0] == "administrative_area_level_1" }).length){
        this.errorMessage = "Please insert a valid address";
        return;
      }else if(!place.address_components.filter((c) => { return c.types[0] == "route" }).length){
        this.errorMessage = "Please insert a valid address";
        return;
      }else if(!place.address_components.filter((c) => { return c.types[0] == "street_number" }).length){
        this.errorMessage = "Please insert a valid address";
        return;
      }else if (!this.schoolAssociatedWith) {
        this.errorMessage = "Please insert a valid school";
        return;
      }else if(!this.disabled){
        if(this.price < 0 || !this.price){
          this.errorMessage = "Please insert a valid price";
					return;
        }
      }
      
      this.city = place.address_components.filter((c) => { return c.types[0] == "locality" })[0].long_name
      this.state = place.address_components.filter((c) => { return c.types[0] == "administrative_area_level_1" })[0].long_name
      this.address = place.address_components.filter((c) => { return c.types[0] == "route" })[0].long_name+" "+place.address_components.filter((c) => { return c.types[0] == "street_number" })[0].long_name

      this.longitude = place.geometry.location.lng()
      this.latitude = place.geometry.location.lat()
      this.placeId = place.place_id
      let arrayCoor = [parseFloat(this.longitude) , parseFloat(this.latitude)]
      this.coordinates = arrayCoor

      const data = {
        title: this.title,
        date: this.date + " " + this.time,
        description: this.description,
        address: this.address,
        city: this.city,
        state: this.state,
        coordinates: this.coordinates,
        googlePlaceId: this.placeId,
        schoolAssociatedWith:this.schoolAssociatedWith,
        price: this.price,
      };

      if(this.eventId){
        this.updateEvent({eventId:this.eventId, data})
      }else{
        this.CreateNewEvent(data)
      }
      console.log(data)
      place = ""
      this.coordinates = ""
      this.city = ""
      this.state = ""
      this.address = ""
      this.longitude = ""
      this.latitude = ""
      this.date = ""
      this.time = ""
      this.errorMessage = ""
      this.placeId = ""
      this.schoolAssociatedWith = ""
      this.price = 0
      this.disabled = false
      $('#eventsEditModal').modal('hide')
    },
    emptyMessage(){
      this.longitude = ""
      this.latitude = ""
      this.date = ""
      this.time = ""
      this.placeId = ""
      this.schoolAssociatedWith = ""
      this.price = 0
      this.disabled = false
    }
  },
  computed:{
    dateDay(){
      return moment().format('yyyy-MM-DD')
    },
  },
  mounted(){
    this.googleAutocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    )
    this.googleAutocomplete.setFields(["address_component", "geometry", "place_id"]);
  },
}
</script>