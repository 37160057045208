<template>
  <skillsList :skills="skills" />
</template>
<script>
import { mapActions, mapState} from 'vuex'
import skillsList from '../components/skills-list'
export default {
  data(){
    return{
    }
  },
  components:{
    skillsList,
  },
  computed:{
    ...mapState('skills',['skills'])
  },
  methods:{
    ...mapActions('skills', ['getSkills'])
  },
  created(){
    this.getSkills()
  },
}
</script>