<template>
	<div class="div-content-contact-us">
		<h1 class="title-contact-us">Contact us</h1>
		<p class="text-contact-us">If you have questions, suggestions, or bug reports, please send an email to  support@kasaiapp.com</p>
	</div>
</template>
<script>
export default {
  name:"contactUs",
  data(){
    return{
    }
  },
  components:{
  },
  computed:{
  },
  methods:{
  },
  created(){
  },
}
</script>