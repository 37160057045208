<template>
    <div class="div_home container container-destination">
      <div class="div_title">
        <h1>Kasaibrand</h1>
      </div>
      <div class="home-description">
        Kasai is a connectivity app designed to improve a student’s education by enhancing their school experience. This is achieved by connecting, informing, and promoting students. Kasai is a comprehensive educational connectivity tool that enables users to connect with study buddies and campus related events, providing a richer and more successful educational experience.
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

