<template>
  <div class="modal fade" id="usersEditModal" tabindex="-1" aria-labelledby="objectiveModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="objectiveModalLabel">Teacher</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="emptyMessage"></button>
          </div>
          <form class="w-100 py-5 px-4">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nameEdit" class="labelForm">Name</label>
                <input type="text" id="nameEdit" class="form-control inputsForm"  v-model="name" v-on:keyup.enter="editUser()">
              </div>
              <div class="form-group col-md-6">
                <label for="email" class="labelForm">Email</label>
                <input type="text" class="form-control inputsForm" id="email" v-model="email" v-on:keyup.enter="editUser()">
              </div>
              <div class="form-group col-md-6">
                <label for="password" class="labelForm">Password</label>
                <input type="password" class="form-control inputsForm" id="password" placeholder="Optional*" v-model="password" v-on:keyup.enter="editUser()">
              </div>
              <div class="form-group col-md-6">
                <label for="age" class="labelForm">Age</label>
                <input type="number" class="form-control inputsForm" id="age" v-model="age" v-on:keyup.enter="editUser()">
              </div>
              <div class="form-group col-md-6">
                <label for="school" class="labelForm">School</label>
                <input type="text" class="form-control inputsForm" id="school" v-model="school" v-on:keyup.enter="editUser()">
              </div>
               <div class="form-group col-md-6">
                <label for="major" class="labelForm">Major</label>
                <input type="text" class="form-control inputsForm" id="major" v-model="major" v-on:keyup.enter="editUser()">
              </div>
            </div>

            <p class="text-danger" v-if="errorMessage">
              {{ errorMessage }}
            </p>
            
          </form>


          
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg btnModalSecondary py-2 px-4" data-bs-dismiss="modal" v-on:click="emptyMessage">Close</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess py-2 px-4 ml-3" v-on:click="editUser(user)">Edit</button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name:"ModalAdmin",
  data(){
    return{
      name: "",
      email:"",
      userId:"",
      password: "",
      age: 0,
      school: "",
      major: "",
      errorMessage:"",
    }
  },
  methods:{
    ...mapActions('user', ['updateUser']),
    editUser(){
      const data = {
        name: this.name,
        email: this.email,
        password: this.password,
        age: this.age,
        school: this.school,
        major: this.major,
      };
      this.updateUser({userId:this.userId, data})
      $('#usersEditModal').modal('hide')
    },
  }
}
</script>