<template>
  <div>
    <userList :users="users" />
  </div>
</template>
<script>
import { mapActions, mapState} from 'vuex'
import userList from '../components/user-list';
export default {
  data(){
    return{

    }
  },
  components:{
    userList
  },
  computed:{
    ...mapState('user',['users'])
  },
  methods:{
    ...mapActions('user', ['getSkills', 'getUsers'])
  },
  created(){
    this.getUsers()
  }


}
</script>