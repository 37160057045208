const admins = axios => {
	return {
		async getUsers(){
      return axios.get(`/api/users`)
    },
    async deleteUser(id){
      return axios.delete(`/api/users/${id}`)
    },
    async updateUser(id, data){
      return axios.put(`/api/users/${id}`, data)
    },
	}
}
export default admins;
