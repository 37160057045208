<template>
  <div class="modal fade" id="skillEditModal" tabindex="-1" aria-labelledby="objectiveModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered">

      <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="objectiveModalLabel">Skills</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="emptyMessage"></button>
          </div>

          <form class="w-100 py-5 px-4">

            <div class="form-row">

              <div class="form-group col-md-12">
                <label for="nameEdit" class="labelForm font-weight-bold">Name</label>
                <input type="text" id="nameEdit" class="form-control inputsForm" placeholder="name" v-model="name" v-on:keyup.enter="editSkill()">
              </div>
              <div class="form-group col-md-12">
                <label for="description" class="labelForm font-weight-bold">Description</label>
                <textarea class="form-control inputsForm" id="description" placeholder="description" v-model="description" v-on:keyup.enter="editSkill()" rows="5"></textarea>
              </div>

            </div>

            <p class="text-danger" v-if="errorMessage">
              {{ errorMessage }}
            </p>
            
          </form>


          
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-lg btnModalSecondary py-2 px-4" data-bs-dismiss="modal" v-on:click="emptyMessage">Close</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess py-2 px-4 ml-3" v-on:click="editSkill()" v-if="skillId">Edit</button>
            <button type="button" class="btn btn-primary btn-lg btnModalSuccess py-2 px-4 ml-3" v-on:click="editSkill()" v-else>Add new skill</button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState} from 'vuex'
export default {
  name:"ModalAdmin",
  data(){
    return{
      name: "",
      description:"",
      skillId:"",
      errorMessage:"",
    }
  },
  methods:{
    ...mapActions('skills', ['CreateNewSkill', 'updateSkill']),
    editSkill(){
      this.errorMessage = ""
      if (this.name === "") {
        this.errorMessage = "Please insert a valid name";
        return;
      } else if (this.description === "") {
        this.errorMessage = "Please insert a valid description";
        return;
      }
      const data = {
        name: this.name,
        description: this.description,
      };
      if(this.skillId){
        this.updateSkill({skillId:this.skillId, data})
      }else{
        this.CreateNewSkill(data)
      }
      $('#skillEditModal').modal('hide')
    },
  }
}
</script>