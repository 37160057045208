const skills = axios => {
	return {
		async CreateNewSkill(data){
            return axios.post("/api/skills", data)
        },
		async getSkills(){
            return axios.get(`/api/skills`)
        },
		async deleteSkill(id){
            return axios.delete(`/api/skills/${id}`)
        },
	    async updateSkill(id, data){
            return axios.put(`/api/skills/${id}`, data)
        }
	}
}

export default skills;