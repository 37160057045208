import API from "../../services/api";
import _ from 'lodash'

export default{
  getEvents:( { commit } ) => {
    API.events.getEvents()
      .then(res => commit('setEvents', res.data) )
      .catch(error => commit('setEventsSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  CreateNewEvent:( { commit, dispatch }, data ) => {
    API.events.CreateNewEvent(data)
      .then(res => dispatch('getEvents') )
      .catch(error => commit('setEventsSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteEvent:( { commit, dispatch }, id ) => {
    API.events.deleteEvent(id)
      .then(res => dispatch('getEvents') )
      .catch(error => commit('setEventsSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  updateEvent:( { commit, dispatch }, {eventId, data} ) => {
    API.events.updateEvent(eventId, data)
      .then(res => dispatch('getEvents') )
      .catch(error => commit('setEventsSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

}