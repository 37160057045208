<template>
  <eventsList :events="events" />
</template>
<script>
import eventsList from '../components/events-list'
import { mapActions, mapState} from 'vuex'
export default {
  data(){
    return{
    }
  },
  components:{
    eventsList,
  },
  computed:{
    ...mapState('events',['events'])
  },
  methods:{
    ...mapActions('events', ['getEvents'])
  },
  created(){
    this.getEvents()
  },
}
</script>