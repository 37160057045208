import axios from 'axios';
import user from './user'
import admins from './admins'
import skills from './skills'
import events from './events'
import schools from './schools'


axios.defaults.baseURL = process.env.API_BASE_URL;
// @ts-check

axios.interceptors.request.use(function (config) {
	config.headers = { Authorization: `Token ${window.sessionStorage.getItem('token')}` }
	return config;
}, function (error) {
	return Promise.reject(error);
});

const API = axios => {
	return {
		user: user(axios),
		admins: admins(axios),
		skills: skills(axios),
		events: events(axios),
		schools: schools(axios),
	}
}

export default API(axios);
