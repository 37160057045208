<template>
  <div class="container mt-5 divContainerAdminGeneral">
    <ModalAdmin ref="ModalAdmin" @reloadTeacherList="getUsers"/>    
    <confirmationModal ref="confirmModal" @confirm="deleteUserApi" />
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral">Schools pending</h2>
    </div>
    <hr class="w-100" />
    <p class="text-danger" v-if="errorMessage">
      {{ errorMessage }}
    </p>
    <div class="row w-100 divContainerAdminGeneral mt-3 d-none d-lg-block">      
      <div class="d-flex w-100 mb-2">
        <div class="col-5 titleAdminTableGeneral font-weight-bold">School</div>
        <div class="col-4 titleAdminTableGeneral font-weight-bold">Date</div>
        <div class="col-3 titleAdminTableGeneral"></div>
      </div>
      <div  v-for="(school, index) in schoolsPending" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center">{{school.name}}</div>
        <div class="col-4 textTableAdminGeneral d-flex justify-content-start align-items-center">{{dateDay(school.date)}}</div>
        <div class="col-3 textTableAdminGeneral d-flex justify-content-end align-items-center"><i class="fas fa-times p-2 icon_tool cancelSchool" v-on:click="cancelSchool(school)" ></i><i class="fas fa-check p-2 icon_tool approvalSchool" v-on:click="approvalSchool(school)"></i></div>
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light m-0"  v-if="!loading && !schoolsPending.length">
        <h1 class="m-5">No Users to display.</h1>
      </div>

    </div>

    <div class="row w-100 card-deck m-0 justify-content-start divCardUserList d-lg-none">

      <div class="col-sm-6 col-8 p-0 mt-5"  v-for="(school, index) in schoolsPending" :key="index">
        <div class="card div-card-user">
          <div class="d-flex text-center">
            <h5 class="card-title cardTitleUser col-12 text-center px-2 fw-bold">{{school.name}}</h5>
          </div>

          <div class="row card-body" >

            <span class="col-12 cardSubtitle text-muted mt-2 text-center">Date: </span>
            <span class="col-12 cardContent text-center">{{dateDay(school.date)}}</span>

          </div>

          <div class="d-flex justify-content-around">
            <i class="fas fa-times p-3 icon_tool iconEdit col-2" v-on:click="cancelSchool(school)"></i>
            <i class="fas fa-check p-3 icon_tool iconDelete col-2" v-on:click="approvalSchool(school)"></i>
          </div>
        </div>
      </div>
			<div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light m-0"  v-if="!loading && !schoolsPending.length">
        <h1 class="m-5">No Users to display.</h1>
      </div>
    
    </div>




    <div class="col-12 p-4 text-center" v-if="loading">
      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>          
    </div>

  </div>
</template>
<script>
import API from '../services/api'
import moment from 'moment';
export default {
	name:'schools',
  data(){
    return{
			loading:false,
			errorMessage:"",
			schoolsPending: [],
    }
  },
  components:{
    
  },
  computed:{
		
  },
  methods:{
		cancelSchool(school){
			this.loading = true
			API.schools.deleteSchool(school._id)
				.then(res => {
					this.getSchoolsTEST()
					this.loading = false
				})
			
		},
		approvalSchool(school){
			this.loading = true
			API.schools.approvalSchool(school._id, school)
				.then(res => {
					this.getSchoolsTEST()
					this.loading = false
				})
		},
		getSchoolsTEST(){
			this.loading = true
			API.schools.getSchools()
				.then(res => {
					this.schoolsPending = res.data.pending
					this.loading = false
				})
		},
		dateDay(){
      return moment().format('yyyy/MM/DD h:mm a')
    },
  },
  created(){
		this.getSchoolsTEST()
  },
}
</script>