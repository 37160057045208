<script>
// @ts-check
import { createWebHistory, createRouter } from "vue-router";
import VueRouterMultiguard from 'vue-router-multiguard'
import home from "./views/home.vue";
import login from "./views/login.vue";
import admin from "./views/admin.vue"
import skills from "./views/skills.vue"
import events from "./views/events.vue"
import termsOfService from "./views/terms-of-service.vue"
import privacyPolicy from "./views/privacy-policy.vue"
import contactUs from "./views/contactUs.vue"
import schools from "./views/schools.vue"

import { isLogin, isAdmin } from './services/middlewares'


const routes =[
    {
			path: "/",
			name: "Home",
			component: home,
    },
    {
			path: "/login",
			name: "Login",
			component: login,
    },
    {
			path: "/admin",
			name: "Admin",
			component: admin,
			meta: {routeBelongsTo: "admin"},
			beforeEnter: VueRouterMultiguard([isLogin, isAdmin])
    },
    {
			path: "/admin/skills",
			name: "Skills",
			component: skills,
			meta: {routeBelongsTo: "admin"},
			beforeEnter: VueRouterMultiguard([isLogin, isAdmin])
    },
    {
			path: "/admin/events",
			name: "Events",
			component: events,
			meta: {routeBelongsTo: "admin"},
			beforeEnter: VueRouterMultiguard([isLogin, isAdmin])
		},
    {
			path: "/admin/schools-approvals-pending",
			name: "Schools",
			component: schools,
			meta: {routeBelongsTo: "admin"},
			beforeEnter: VueRouterMultiguard([isLogin, isAdmin])
		},
		{
			path: "/terms-of-service",
			name: "Terms of Service",
			component: termsOfService,
    },
    {
			path: "/privacy-policy",
			name: "Privacy Policy",
			component: privacyPolicy,
    },
    {
			path: "/contact-us",
			name: "Contact us",
			component: contactUs,
    },
  ]

export default routes;
</script>
