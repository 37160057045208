<template>
  <div class="container d-flex flex-column justify-content-center align-items-center mt-5">
    <confirmationModal ref="confirmModal" @confirm="deleteSkillAPI" />
    <modalSkills ref="saveModalSkills" :user="user"/>
    <div class="divAdminGeneral w-100">
      <h2 class="titleAdminGeneral">Skills</h2>
      <div class="d-flex flex-column align-items-end">
        <div>
          <button type="button" class="btn btn_admin_general" v-on:click="openModalSkills()">Add Skill</button>
        </div>
      </div>
    </div>
    <hr class="w-100" />
    <div class="row w-100 divContainerAdminGeneral mt-2">      
      <div class="d-flex w-100 mb-2">
        <div class="col-5 titleAdminTableGeneral font-weight-bold">Name</div>
        <div class="col-5 titleAdminTableGeneral font-weight-bold">Description</div>
        <div class="col-1 titleAdminTableGeneral"></div>
        <div class="col-1 titleAdminTableGeneral"></div>
      </div>
      <div v-for="(skill, index) in skills" :key="index" :class="'badges-list-border d-flex '+(index%2 ? 'row-collapse-color2' : 'row-collapse-color1')">
        <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center">{{skill.name}}</div>
        <div class="col-5 textTableAdminGeneral d-flex justify-content-start align-items-center">{{skill.description}}</div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-edit p-2 icon_tool iconEdit" v-on:click="openModalSkills(skill)"></i></div>
        <div class="col-1 textTableAdminGeneral d-flex justify-content-start align-items-center"><i class="fas fa-trash p-2 icon_tool iconDelete" v-on:click="openConfirmationModal(skill)"></i></div>
      </div>

      <div class="badges-list-border col-12 row row-collapse-color2 text-center bg-light" v-if="!loading && !skills.length">
        <h1 class="m-5">No Skills to display.</h1>
      </div>
      <div class="col-12 p-4 text-center" v-if="loading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>          
      </div>
    </div>
    
  </div>
</template>
<script>
import { mapActions, mapState} from 'vuex'
import confirmationModal from './confirmationDeleteModal.vue';
import modalSkills from './ModalSkills.vue';
export default {
  name:"skillsList",
  data() {
    return{
      loading:false,
    }
  },
  components:{
    confirmationModal,
    modalSkills
  },
  props:['skills'],
  methods:{
    ...mapActions('skills', ['deleteSkill']),
    deleteSkillAPI(id){
      this.loading = true
      this.deleteSkill(id)
      this.loading = false
    },
    openConfirmationModal(skill = {}){
      this.$refs.confirmModal.message = "Are you sure you want to delete this? " + skill.name || ""
			this.$refs.confirmModal.assetId = skill._id || ""
      $('#confirmationModal').modal('show')
    },
    openModalSkills(skill = {}){
      this.$refs.saveModalSkills.name = skill.name || ""
			this.$refs.saveModalSkills.description = skill.description || ""
			this.$refs.saveModalSkills.skillId = skill._id || ""
      $('#skillEditModal').modal('show')
    }
  }
}
</script>