const schools = axios => {
	return {
		async getSchools(){
			return axios.get(`/api/schools`)
		},
		async deleteSchool(id){
            return axios.delete(`/api/schools/${id}`)
        },
		async approvalSchool(id, data){
            return axios.put(`/api/schools/approve/${id}`, data)
        }
	}
}

export default schools;