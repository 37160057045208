const events = axios => {
	return {
		async CreateNewEvent(data){
            return axios.post("/api/events", data)
        },
		async getEvents(){
            return axios.get(`/api/events`)
        },
		async deleteEvent(id){
            return axios.delete(`/api/events/${id}`)
        },
	    async updateEvent(id, data){
            return axios.put(`/api/events/${id}`, data)
        }
	}
}

export default events;