<template>
    <div class="div_login_container container">
        <div class="div_login d-flex flex-column align-items-center shadow p-3 mb-5 bg-white rounded">

						<div class="h-25 d-flex justify-content-center">
							<h3 class="mt-5 fontFamily h1 f-weight-700">Login</h3>
						</div>

						<form class="h-75 w-100 d-flex flex-column align-items-center justify-content-start">
							<div class="form-row">

								<div class="form-group col-md-12 mt-5 d-flex align-items-center justify-content-center">
									<input type="email" class="form-control inputsFormLogin w-75" id="email" placeholder="email" aria-describedby="emailHelp" v-model="email"  v-on:keyup.enter="login">
								</div>

								<div class="form-group col-md-12 mt-5 d-flex align-items-center justify-content-center">
									<input type="password" class="form-control inputsFormLogin w-75" id="password" placeholder="password" v-model="password"  v-on:keyup.enter="login">
								</div>

								<div class="w-100 div_login_button mt-5">
									<input :class="{'btn btn-primary btn-lg btnModalSuccess':!loading, 'btn btn-primary btn-lg btnModalSuccess disabled':loading}" type="button" value="Log In"  v-on:click="login" >
								</div>
                <p class="text-danger" v-if="errorMessage">{{errorMessage}}</p>

							</div>
						</form>
        </div>
				<tr v-if="loading"  >
					<td colspan="5" class="text-center pt-3">
						<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
					</td>
				</tr>
    </div>
</template>

<script>
import API from '../services/api'
import jwt_decode from 'jwt-decode'
import _ from 'lodash'
import { mapActions, mapState} from 'vuex'
export default {
    data(){
        return{
            email:'',
            password:'',
            errorMessage:'',
						loading: false
        }
    },
    methods: {
      ...mapActions('user', ['saveUser']),
      login(){
          this.errorMessage = ""
          if(!this.email || this.email.length < 6 || this.email.length >35){
              this.errorMessage = "Email Error"
              return
          }            
          if(!this.password || this.password.length < 6 || this.password.length >= 20){
              this.errorMessage = "Password Error"
              return
          }

          const data = {
            email: this.email,
            password: this.password
          }
          
          this.loading = true
          API.user.Login(data)
              .then(res => {
                  let userDecode = jwt_decode(res.data.token)
                  sessionStorage.setItem('token', res.data.token)
                  sessionStorage.setItem('user', JSON.stringify(userDecode))
                  this.$emit('userLogin', {userType : userDecode.role})
                  this.saveUser()
                  this.$router.push('/'+userDecode.role)
                  this.loading = false
              })
              .catch(error => {
                  console.log(error)
                  this.errorMessage = _.get(error, "response.data.errors.message", "Error please try later.")
                  this.loading = false
              })
        }
    },
    computed:{
      ...mapState('user',['user'])
	  }, 
}
</script>