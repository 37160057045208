import API from "../../services/api";
import _ from 'lodash'

export default{
  getSkills:( { commit } ) => {
    API.skills.getSkills()
      .then(res => commit('setSkills', res.data) )
      .catch(error => commit('setErrorSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  CreateNewSkill:( { commit, dispatch }, data ) => {
    API.skills.CreateNewSkill(data)
      .then(res => dispatch('getSkills') )
      .catch(error => commit('setErrorSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  deleteSkill:( { commit, dispatch }, id ) => {
    API.skills.deleteSkill(id)
      .then(res => dispatch('getSkills') )
      .catch(error => commit('setErrorSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

  updateSkill:( { commit, dispatch }, {skillId, data} ) => {
    API.skills.updateSkill(skillId, data)
      .then(res => dispatch('getSkills') )
      .catch(error => commit('setErrorSkillsApi', _.get(error, "response.data.errors.message", "Error please try later.") ) )
  },

}